<template>
  <cw-page
    icon="work"
    class="cw-business-loan-start"
  >
    <template #hero-title>
      <translate>
        Welcome to Saldo Corporate loan application!
      </translate>
    </template>
    <v-row>
      <v-col
        cols="24"
        md="12"
      >
        <v-alert
          border="left"
          class="confirmation__terms mb-0"
          color="primary"
          text
        >
          <h3 class="text-subtitle-1 font-weight-bold mb-1">
            <translate>
              Yrityslainojen myöntäminen on päättynyt.
            </translate>
          </h3>
          <p>
            <translate>
              Tact Finance Oy on lopettanut yrityslainojen myöntämisen marraskuussa 2024. Uusia
              yrityslainoja voi edelleen hakea Saldo Bankilta osoitteessa
            </translate>
            <a href="https://www.saldo.com/fi-fi/yritysasiakkaat/yrityslaina/">www.saldo.com</a>
          </p>
        </v-alert>
      </v-col>
      <v-col
        md="12"
        cols="24"
      />
    </v-row>
  </cw-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { goToError } from '@shared/mixins';
import { DataLayer } from '@shared/services/dataLayer';

export default {
  name: 'CwBusinessLoanStart',

  mixins: [goToError],

  data: () => ({
    form: {
      appliedLoanLimit: null,
      appliedInstalmentCount: null,
      dataGatheringPermission: 'off',
      dataHandlingPermission: 'off',
      prefilledBusinessId: null,
    },
  }),

  computed: {
    ...mapGetters({
      initialData: 'application/getInitialData',
      locale: 'application/locale',
    }),

    isApplicant() {
      return this.initialData.currentPage === 'businessLoanStart';
    },

    infoText() {
      return this.isApplicant
        ? this.$gettext('The loan application requires approval of the Privacy Policy and Customer Data Review. Once both are approved, you can continue to authenticate.')
        : this.$gettext('Signing up for our service will take you to a loan offer. Continuation requires acceptance of the Privacy Policy and Customer Data Review.');
    },

    prefilledBusinessIdHint: vm => vm.$gettext('Business ID in the form of 1234567-8'),
    prefilledBusinessIdLabel: vm => vm.$gettext('Business ID'),
  },

  created() {
    this.$dataLayer.PushToECommerce({
      params: {
        action: 'businessLoanDetail',
        formData: this.form,
      },
    });

    this.form.appliedLoanLimit = this.initialData.appliedLoanLimit;
    this.form.appliedInstalmentCount = this.initialData.appliedInstalmentCount;
  },

  methods: {
    ...mapActions({
      submitForm: 'application/submit',
    }),
    ...mapGetters({
      isBrokered: 'application/isBrokered',
    }),

    isBrokerFlow() {
      return this.isBrokered();
    },

    async validateForm() {
      const valid = await this.$refs.observer.validate();
      this.$dataLayer.PushToECommerce({
        params: {
          action: 'b2bStepValidationClick',
          formData: this.form,
        },
      });

      DataLayer.onValidationClick();

      if (!valid) {
        this.goToError();
        DataLayer.onValidationFail();

        return;
      }
      this.$dataLayer.PushToECommerce({
        params: {
          action: 'b2bStepValidationSubmit',
          formData: this.form,
        },
      });
      await this.submit();
    },

    async submit() {
      this.$wait.start('SUBMIT_FORM');
      const routineParams = {
        uri: 'executeRoutine',
        routine: this.isApplicant ? 'Start' : 'Submit Signing Start',
      };

      try {
        if (!this.isApplicant) {
          delete this.form.appliedLoanLimit;
          delete this.form.appliedInstalmentCount;
          delete this.form.prefilledBusinessId;
        } else {
          this.$dataLayer.PushToECommerce({
            params: {
              action: 'addToCartB2b',
              formData: this.form,
            },
          });
        }

        await this.submitForm({
          formParams: this.form,
          routineParams,
        });
      } catch (e) {
        this.$wait.end('SUBMIT_FORM');
      }
    },
  },
};
</script>

<style lang="scss">
.underline {
  text-decoration: underline;
}
</style>
